:root {
  --themeBlue: #17447E;
  --colorBlue: #251CC9;
}

.signupPage {
  padding: 40px 0;
  position: relative;
}
#__react-alert__ {
  z-index: 11000 !important;
}
#__react-alert__ div{
  z-index: 11000 !important;
}
.signupPage .logoDiv {
  margin-left: 50px;
}
.bg-red {
  background: red;
}

.signupPage .sigupRow {
  padding: 40px 0;
}

.signupPage .sigupRow .signupCover {
  /* transform: scale(1.85); */
  position: relative;
  top: 0px;
  animation: MoveUpDown11 1.5s linear infinite;
}
@keyframes MoveUpDown11 {
  0%, 100% {
      top: 0px;
  }
  50% {
      top: 30px;
  }
}
.signupPage .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 25px;
}

.d-flex {
  display: flex;
}

.jc-sb {
  justify-content: space-between;
}

.ai-center {
  align-items: center;
}

.signupPage h2 {
  color: var(--themeBlue);
  font-size: 45px;
  font-weight: bold;
}

.signupPage label {
  font-weight: 500;
}

.signupPage .forget {
  font-weight: 500;
  cursor: pointer;
}

.signupPage .signBtn {
  padding: 16px;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  /* background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA 51%, #1FA2FF 100%); */
  background: #251CC9;
  border-radius: 30px;
  background-size: 200%;
  transition: all .3s;
  width: 100%;
  color: white;
  font-size: 19px;
  font-weight: 500;
}

.signupPage .signBtn:hover {
  background-position: right center;
  background: #1E179F;
  /* change the direction of the change here */
  color: white !important;
}

.signupPage .alreadySign {
  font-size: 15px;
  margin-top: 30px;
}

.signupPage .alreadySign a {
  color: var(--colorBlue);
  font-weight: 500;
}

.signupPage .element {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  height: 100%;
  max-width: 55%;
}

.homePage {
  background: #F1F1F1;
  padding-top: 12px;
  /* background-color: #a2cefa; */
  /* background-image: linear-gradient(180deg, #a9d4ff 0%, #f3f6ff 100%); */
  background: #F6F6F6;
  min-height: 100vh;
}

.homePage .navbarCustome {
  z-index: 99999;
  padding: 8px 20px;
  /* margin-top: 40px; */
  background: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.homePage .navbarCustome .linkDiv {
  display: flex;
  align-items: center;
}
.homePage .navbarCustome .notify .notCount {
  font-weight: 600;
  position: absolute;
  top: 1px;
  right: 3px;
  right: 1px;
  width: 19px;
  height: 19px;
  font-size: 11px;
  display: inline-block;
  border-radius: 50%;
  background: #eb0000;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homePage .navbarCustome .linkDiv .deleteNoti {
  width: 22px;
  height: 22px;
}
.homePage .navbarCustome .linkDiv img {
  max-height: 30px;
  transition: all 0.3s;
}

.homePage .navbarCustome .linkDiv img:hover {
  transform: scale(1.1);
  transition: all 0.3s;
}

.homePage .navbarCustome .linkDiv .active button {
  border-radius: 20px;
  font-size: 16px;
  padding: 4px 10px 5px 10px;
  background: #34e89e;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #039b57, #0f5e45);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #039b57, #0f5e45);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
  text-transform: capitalize;
  border: none;
}
.homePage .copyLink{
  position: relative;
  cursor: pointer;
}
.homePage .copyLink .copyText{
  position: relative;
  background: #000;
  border-radius: 6px;
  color: #fff;
  display: none;
  font-weight: 400;
  left: 50%;
  padding: 4px 10px;
  position: absolute;
  top: 55px;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  width: -webkit-max-content;
  width: max-content;
  z-index: 10;
}
.homePage .copyLink:hover .copyText{
display: block;
}
.form-check-admin-create {
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display: flex;
}
.homePage .notifyDropD {
  width: 400px;
}
.homePage .notifyDropD .notiBtn .showBtn {
  text-align: right;
    padding: 0 6px;
    font-size: 13px;
    color: #3669bb;
}
.homePage .notifyDropD .notiBtn .showBtn {
  box-shadow: rgb(50 50 105 / 15%) 0px 2px 5px 0px, rgb(0 0 0 / 5%) 0px 1px 1px 0px;
    /* background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA 51%, #1FA2FF 100%); */
    background-image: linear-gradient(0deg, #12D8FA 0%, #1FA2FF 100%);
    border-radius:7px;
    /* background-size: 200%; */
    font-weight: 500;
    transition: all .3s;
    border: none;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    /* width: 100%; */
    width: 100%;
    margin: auto;
    padding-right: 25px;
    padding-left: 25px;
    color: white;
    text-decoration: none;
    text-align: center;
    font-size: 13px;
  }
  .homePage .notifyDropD .notiBtn .showBtn:hover {
  text-decoration: none;
  background-position: right center;
  /* change the direction of the change here */
  opacity: 0.8;
}
.homePage .notifyDropD .notiBtn .clearBtn:hover {
  opacity: 0.8;
}
.homePage .notifyDropD .notiBtn .clearBtn {
  font-size: 13px;
    background: #ed0000;
    box-shadow: rgb(50 50 105 / 15%) 0px 2px 5px 0px, rgb(0 0 0 / 5%) 0px 1px 1px 0px;
    /* background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA 51%, #1FA2FF 100%); */
    border-radius:7px;
    /* background-size: 200%; */
    font-weight: 500;
    transition: all .3s;
    border: none;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    /* width: 100%; */
    width: 100%;
    margin: auto;
    padding-right: 25px;
    padding-left: 25px;
    color: white;
    text-decoration: none;
    text-align: center;
}
.homePage .notifyDropD .head{
  padding: 10px 15px;
  font-weight: bold;
  text-transform: capitalize;
  border-bottom: 1px solid #e4e5e7;  

}
.homePage .notifyDropD .head img{
  width: 20px;
  margin-right: 10px;
}
.homePage .notifyDropD .notRowMain{
  /* max-height: 300px; */
  /* overflow-x: hidden; */
    /* overflow-y: auto; */
  position: relative;
}

/* width */
.homePage .notifyDropD .notRowMain::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.homePage .notifyDropD .notRowMain::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
.shadow-none {
  box-shadow: none;
}
.shadow-none:hover {
  box-shadow: none;
}
 
/* Handle */
.homePage .notifyDropD .notRowMain::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.homePage .notifyDropD .notRowMain::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.homePage .notifyDropD .notRow .fullDate {
  font-size: 13px;
  padding: 5px 7px;
  transform: translate(0, -50%);  
  left: 0%;
  bottom: -43px;
}
.homePage .notifyDropD .notRow{
  padding: 15px;
  border-bottom: 1px solid #e4e5e7; 
  display: flex;
  align-items: center;
  justify-content: space-between; 
}
.homePage .notifyDropD .notRow:hover{
  background: #f3f6ff ;
}
.homePage .notifyDropD .notRow p{
  font-size: 14px;
  line-height: 15px;
  max-height: 45px;
  overflow: hidden;
  position: relative;
  margin: 0;
}
.homePage .notifyDropD .notRow .time {
  color: #b5b6ba;
    margin-top: 5px;
    font-size: 12px;
}

.homePage .navbarCustome .linkDiv .active span {
  border: white 1px solid;
  background: red;
  position: relative;
  top: -3px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
}

.modal-amount {
  border: none;
  border-radius: 5px;
  outline: none;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  background-color: #d8d8d860;
  color: rgb(27, 27, 27)
}

.homePage .navbarCustome .linkDiv .active span::before {
  content: "";
  border: 2px solid red;
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  animation: zoomBig1 2s linear infinite;
  -webkit-animation-delay: .85s;
  animation-delay: .85s;
}

@keyframes zoomBig1 {
  0% {
    width: 5px;
    height: 5px;
    opacity: 1;
  }

  50% {
    width: 10px;
    height: 10px;
    opacity: 0.8;
  }

  100% {
    width: 15px;
    height: 15px;
    opacity: 0;
  }
}

.homePage .navbarCustome .linkDiv .inactive span {
  position: relative;
  top: -3px;
  border: white 1px solid;
  background: green;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
}

.homePage .navbarCustome .linkDiv .inactive span::before {
  content: "";
  border: 2px solid green;
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  animation: zoomBig 2s linear infinite;
  -webkit-animation-delay: .85s;
  animation-delay: .85s;
}

@keyframes zoomBig {
  0% {
    width: 5px;
    height: 5px;
    opacity: 1;
  }

  50% {
    width: 10px;
    height: 10px;
    opacity: 0.8;
  }

  100% {
    width: 15px;
    height: 15px;
    opacity: 0;
  }
}

.homePage .navbarCustome .linkDiv .inactive button {
  border-radius: 20px;
  font-size: 16px;
  color: white;
  border: none;
  padding: 4px 10px 5px 10px;
  background: #ED213A;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #93291E, #ED213A);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #93291E, #ED213A);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  text-transform: capitalize;
}

.paynowHeaderBtn {
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  /* background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA 51%, #1FA2FF 100%); */
  background: #5CE7C4;
  border-radius: 18px;
  background-size: 200%;
  border: none;
  background-color: #17447E;
  padding: 8px 20px;
  margin-left: 10px;
  color: white;
  font-size: 18px;
  transition: all 0.3s;
}

.section-rdbtn {
  display: flex;
  flex-flow: row wrap;
}

.section-rdbtn>div {
  flex: 1;
  padding: 0.5rem;
}

.input-rdbtn {
  display: none;
}

.input-rdbtn:not(:disabled)~.label {
  cursor: pointer;
}

.input-rdbtn:disabled~.label {
  color: #bcc2bf;
  border-color: #bcc2bf;
  box-shadow: none;
  cursor: not-allowed;
}

.label-rdbtn {
  height: 100%;
  display: block;
  background: white;
  border: 2px solid #5CE7C4;
  border-radius: 20px;
  padding: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  box-shadow: 0px 3px 10px -2px rgba(161, 170, 166, 0.5);
  position: relative;
  cursor: pointer;
}

.input-rdbtn:checked+.label-rdbtn {
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA 51%, #1FA2FF 100%);
  background: #5CE7C4;
  background-size: 200%;
  color: white !important;
}

.input-rdbtn:checked+.inputRadio-head {
  color: white !important;
}

.input-rdbtn[type=radio]:checked+.label-rdbtn::after {
  color: #3d3f43;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f007";
  border: 2px solid #12D8FA;
  content: "";
  font-size: 24px;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  background: white;
  box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.25);
}

.input-rdbtn#control_05:checked+.label-rdbtn {
  background: red;
  border-color: red;
}

.inputRadio-head {
  position: relative;
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  color: #5CE7C4;
}

.input-rdbtn:checked+.label-rdbtn .inputRadio-head {
  color: white;
}

.input-rdbtn:checked+.label-rdbtn .inputRadio-head::after {
  background-color: #fff;
}

.inputRadio-head::after {
  content: "";
  position: absolute;
  width: 60px;
  height: 2px;
  background-color: #5CE7C4;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.inputRadio-text {
  font-size: 30px;
  padding-top: 3px;
}

.paynowHeaderBtn:hover {
  background-position: right center;
  background: #58D2B3;
  /* change the direction of the change here */
  transition: all 0.3s;
}

.homePage .navbarCustome .logoDiv {
  position: relative;
  /* bottom: -5px; */
}

.homePage .navbarCustome .logoDiv img {
  height: 62px;
}

.homePage .profileSec {
  text-align: center;
  margin-top: -145px;
}

.homePage .profileSec .profileImg::after {
  background: #a29ca9;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #a29ca9 0%, #95909b 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #a29ca9 0%, #95909b 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #a29ca9 0%, #95909b 99%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a29ca9', endColorstr='#95909b', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.9s ease 0s;
}

.homePage .profileSec .profileImg {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #5CE7C4;
  background: #5CE7C4;
  margin: auto;
  position: relative;

}

.homePage .profileSec .profileImg img {
  width: 100%;
  height: auto;
}

.homePage .profileSec .name {
  font-size: 35px;
  font-weight: bold;
  line-height: 1;
  margin-top: 7px;
}


.homePage .profileSec .designation.inactive {
  background: linear-gradient(to left, #93291E, #ED213A);
}
.homePage .profileSec .designation {
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  background: linear-gradient(to left, #039b57, #0f5e45);
  border-radius: 22px;
  color: white;
  padding: 12px;
  font-size: 18px;
  font-weight: 500;
  border: none;
  width: 100%;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.3s;
}
/* .homePage .profileSec .designation {
  font-size: 20px;
  font-weight: 500;
} */

.homePage .navigationCard {
  padding: 25px 0;
}

.homePage .sidebarMain {
  position: relative;
  top: -110px;
}
.homePage .sidebarMain .menuText {
  padding: 15px;
  border-bottom: 1px solid #E2E2E2;
  text-align: center;
  font-size: 20px;
  color: #222737;
  margin-bottom: 20px;
  font-weight: 500;
}

.homePage .userNavigationMain {
  /* display: flex;
  flex-wrap: wrap; */
  /* padding: 20px; */
  padding-bottom: 20px;
  border-radius: 16px;
  background: white;
  border: 1px solid #E8E8E8;
}

.homePage .signalCard {
  /* display: flex;
  flex-wrap: wrap; */
  padding: 20px;
  border-radius: 16px;
  background: white;
  border: 1px solid #E8E8E8;
  margin-bottom: 15px;
  text-align: center;
}
.homePage .signalCard .imgDiv img{
  max-width: 100%;
}
.homePage .signalCard .imgDiv{
  max-width: 100%;
  margin-bottom: 15px;
}
.homePage .signalCard.news h4{
  padding-bottom: 13px;
  border-bottom: 1px solid #E8E8E8;
}

.homePage .signalCard.news p{
  text-align: left;
}
.homePage .signalCard p{
  color: #969696;
  font-size: 15px;
}
.homePage .signalCard .button_payment {
  text-align: center;
}
.homePage .signalCard .button_payment a {
  box-shadow: rgb(50 50 105 / 15%) 0px 2px 5px 0px, rgb(0 0 0 / 5%) 0px 1px 1px 0px;
    /* background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA 51%, #1FA2FF 100%); */
    /* background-image: linear-gradient(0deg, #12D8FA 0%, #1FA2FF 100%); */
      background: #5CE7C4;
    border-radius: 15px;
    /* background-size: 200%; */
    font-weight: 500;
    transition: all .3s;
    border: none;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    /* width: 100%; */
    max-width: 120px;
    margin: auto;
    padding-right: 25px;
    padding-left: 25px;
    color: white;
    text-decoration: none;
  }
  .homePage .signalCard .button_payment a:hover {
  text-decoration: none;
  background-position: right center;
    background: #58D2B3;
  /* change the direction of the change here */
}

.homePage .userNavigationMain .link {
  /* box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px; */
  border-radius: 25px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: 4px;
  margin-right: 25px;
  border: none;
  padding: 11px 20px;
  padding-right: 0;
  font-size: 16px;
  color: #969696;
  font-weight: 500;
  /* margin-right: 12px; */
  /* margin-bottom: 15px; */
  text-decoration: none;
  transition: all 0.3s;
  display: block;
  display: flex;
  align-items: center;
}

.homePage .userNavigationMain .link:hover, .homePage .userNavigationMain .link.active {
  /* background-image: linear-gradient(0deg, #12D8FA 0%, #1FA2FF 100%); */
  background: #5CE7C4;
  /* change the direction of the change here */
  transition: all 0.3s;
  /* border: 2px solid #fff; */
  color: #fff;
  font-weight: 500;
}

.homePage .userNavigationMain .link svg {
  width: 29px;
  margin-right: 15px;
}

.homePage .userNavigationMain .link:hover svg, .homePage .userNavigationMain .link.active svg {
  color: white;
  fill: white;
}

.homePage .userNavigationMain .link:hover svg path, .homePage .userNavigationMain .link.active svg path {
  color: white;
  fill: white;
}

.homePage .userNavigationMain .link:last-child {
  margin-right: 0;
}

.homePage .paymentMain {
  background: white;
  padding: 30px;
  border-radius: 12px;
}
.homePage .paymentMain .searchUser{
  display: flex;
}
.homePage .paymentMain .searchUser .inputMain{
  display: flex;
  align-items: center;
  background: #e2e2e25e !important;
  max-width: 300px;
  width: 100%;
}
.homePage .paymentMain .searchUser i{
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  background: rgba(0,0,0,0.2);
}
.homePage .paymentMain .searchUser .inputMain input{
  border: none;
  background: transparent;
}
.homePage .paymentMain .searchUser .edit-account-pay{
  max-width: 70px;
  text-align: center;
  cursor: pointer;
}


.homePage .paymentMain .adminUserEditBtn {
  display: flex;
}

.homePage .paymentMain .paymentBanner {
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 20px;
}

.homePage .paymentMain .note {
  font-size: 13px;
  text-align: center;
}

.homePage .paymentMain .note .span {
  font-size: 15px;
  font-weight: 500;
}

.homePage .liveMain {
  background: white;
  padding: 30px;
  border-radius: 12px;
  max-height: 1300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #E8E8E8;
}

.homePage .liveMain iframe {
  width: 100%;
  min-height: 500px;
}
.homePage .projectsRow {
  margin-top: 30px;
}
.homePage .projectsRow .projectCard{
  background: white;
  padding: 25px 20px;
  border-radius: 12px;
  border: 1px solid #E8E8E8;
  text-align: center;
  height: 100%;
  display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.3s;
    position: relative;
    top: 0;
    text-decoration: none;
  }
  .homePage .projectsRow .projectCard:hover {
    transition: all 0.3s;
    top: -10px;
  }
.homePage .projectsRow .projectCard .title{
   color: #222737;
   font-size: 15px;
   font-weight: bold;
   margin-bottom: 20px;
}
.homePage .projectsRow .projectCard .imgDiv{
   height: 125px;
}
.homePage .projectsRow .projectCard .imgDiv img{
   max-width: 100%;
   max-height: 100%;
}



.livetitle {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}

.liveTime {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}

.liveTime_timer {
  padding: 20px;
  background: rgb(45, 45, 45);
  font-size: 25px;
  border-radius: 20px;
  font-weight: 700;
  color: white;
  letter-spacing: 5px;
}

.noLive {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}

.homePage .paymentMain .subsBtn {
  text-align: center;
}

.homePage .paymentMain .subsBtn button {
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  /* background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA 51%, #1FA2FF 100%); */
  background: var(--colorBlue);
  border-radius: 26px;
  background-size: 200%;

  color: white;
  padding: 12px;
  font-size: 18px;
  font-weight: 500;
  border: none;
  width: 100%;
  max-width: 300px;
  transition: all 0.3s;
}
.homePage .paymentMain .subsBtn button:disabled{
  cursor: not-allowed !important;
}
.homePage .paymentMain .subsBtn button:disabled:hover{
  cursor: not-allowed !important;
  background-position: unset;
}
.issueButton {
  width: 60%;
}

.issueButton button {
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  /* background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA 51%, #1FA2FF 100%); */
   background: var(--colorBlue);
  border-radius: 26px;
  background-size: 200%;

  color: white;
  padding: 12px;
  font-size: 18px;
  font-weight: 500;
  border: none;
  width: 100%;
  transition: all 0.3s;
}

.issueButton button:hover {
  background-position: right center;
  background: #1E179F;
  /* change the direction of the change here */
  transition: all 0.3s;

}

.homePage .paymentMain .subsBtn button:hover {
  background-position: right center;
  background: #1E179F;
  /* change the direction of the change here */
  transition: all 0.3s;

}

th {
  background-color: #C8F2E7 !important;
}
td {
  /* background-color: #e2e2e25e !important; */
  border-bottom: .5px rgb(255, 255, 255) solid;
  vertical-align: middle;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
th {
  border-bottom: .5px solid rgb(255, 255, 255)  !important;
}

table {
  text-align: center;
}

.btn-tb-r {
  text-align: start !important;
}

.subs_md-btn {
  width: 100% !important;
  margin: 0 !important;
}

.ban-account.bg-red {

  background-image: linear-gradient(to right, red 0%, red 51%, red 100%);
}
.ban-account {
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA 51%, #1FA2FF 100%);
  border-radius: 16px;
  background-size: 200%;
  color: white;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  width: 100%;
  max-width: 150px;
  transition: all 0.3s;
}

.ban-account:hover {
  background-position: right center;
  /* change the direction of the change here */
  transition: all 0.3s;
}

.manage-admin-btn {
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA 51%, #1FA2FF 100%);
  border-radius: 16px;
  background-size: 200%;

  color: white;
  padding: 10px;
  font-size: 15px;
  font-weight: 500;
  border: none;
  width: 100%;
  max-width: 50px;
  transition: all 0.3s;
  margin-left: 10px;
}

.manage-admin-btn:hover {
  background-position: right center;
  transition: all 0.3s;
}


.manage-admin-btn img {
  width: 20px;
}

.permitCont {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.permissons-check {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* overflow: auto; */
  flex-wrap: wrap;
}

.permitCont span {
  background: orange;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  font-weight: 600;
  color: white;
}

.permission-text-blue {
  background: rgb(15, 155, 197);
  padding: 8px;
  margin: 5px;
  border-radius: 10px;
  color: white;
}

.permission-text-green {
  background: rgb(0, 136, 129);
  padding: 8px;
  margin: 5px;
  border-radius: 10px;
  color: white;
}

.permission-text-red {
  background: rgb(117, 14, 0);
  padding: 8px;
  margin: 5px;
  border-radius: 10px;
  color: white;
}

.permission-text-grey {
  background: rgb(56, 56, 56);
  padding: 8px;
  margin: 5px;
  border-radius: 10px;
  color: white;
}

.permission-text-orange {
  background: rgb(230, 114, 6);
  padding: 8px;
  margin: 5px;
  border-radius: 10px;
  color: white;
}


.table-admin-manage tbody tr td {
  vertical-align: middle;
}



.edit-account-pay {
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA 51%, #1FA2FF 100%);
  border-radius: 16px;
  background-size: 200%;

  color: white;
  padding: 10px;
  font-size: 15px;
  font-weight: 500;
  border: none;
  width: 100%;
  max-width: 50px;
  transition: all 0.3s;
  margin-left: 10px;
}

.edit-account-pay:hover {
  background-position: right center;
  transition: all 0.3s;
}

.edit-account-pay img {
  width: 20px;
}

.homePage .profileMain {
  background: white;
  padding: 40px 30px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.homePage .profileMain .input-cont .phone label {
  transform: translate(14px, -9px) scale(0.75) !important;
  background: white;
  padding: 0 2px;
}

.homePage .profileMain form .input-cont .subsBtn button {
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  /* background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA 51%, #1FA2FF 100%); */
  background: #5CE7C4;
  border-radius: 26px;
  background-size: 200%;

  background-size: 200%;
  color: white;
  padding: 12px;
  font-size: 18px;
  font-weight: 500;
  border: none;
  width: 100%;
  transition: all 0.4s;
}

.homePage .profileMain form .input-cont .subsBtn button:hover {
  background-position: right center;
  background: #58D2B3;
  /* change the direction of the change here */
  transition: all 0.4s;
}

.homePage .profileMain form {
  width: 60%;
}

.homePage .profileMain .saveAlertM {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: brightness(0.5);
  z-index: 10;
}

.homePage .profileMain .saveAlert i {
  font-size: 40px;
  color: #039b57;
}

.homePage .profileMain .saveAlert {
  width: 300px;
  height: 200px;
  background: white;
  border-radius: 20px;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
}


.imgLoader {
  width: 45px;
  height: 45px;
  display: inline-block;
  padding: 0px;
  border-radius: 100%;
  border: 5px solid;
  border-top-color: rgb(255, 255, 255);
  border-bottom-color: rgba(255, 255, 255, 0.3);
  border-left-color: rgb(255, 255, 255);
  border-right-color: rgba(255, 255, 255, 0.3);
  -webkit-animation: imgLoader 1s ease-in-out infinite;
  animation: imgLoader 1s ease-in-out infinite;
}

.input_file_profilepic {
  display: none;
}

@keyframes imgLoader {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes imgLoader {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.globalLoader {
  position: fixed;
  top: 0;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  width: 100vw;
  left: 0;
  height: 100vh;
  background: rgba(0, 0, 0, .2);
  z-index: 1211;
}

.globalLoader .loader {
  width: 100%;
  max-width: 300px;
  padding: 40px 0;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background: #fff;
  border-radius: 20px;
  position: fixed;
}


.gloabalModal {
  position: fixed;
  overflow: auto;
  top: 0;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  width: 100vw;
  left: 0;
  height: 100vh;
  background: rgba(0, 0, 0, .2);
  z-index: 1111;
}

.gloabalModal .modal_visible {
  width: 100%;
  max-width: 700px;
  padding: 40px 8px;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background: #fff;
  border-radius: 20px;
  position: relative;
}

.gloabalModal p {
  color: black;
  font-size: 22px;
  font-weight: 600;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  color: rgb(37, 37, 37);
  font-size: 18px;
  font-weight: 600;
}

.gloabalModal .button button:first-child {
  margin-right: 10px;
}

.gloabalModal .button button {
  border: none;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  /* background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA 51%, #1FA2FF 100%); */
  background: #5CE7C4;
  border-radius: 15px;
  background-size: 200%;
  transition: all .3s;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 20px;
  padding-left: 20px;
  color: white;
}

.gloabalModal .button button:hover {
  background-position: right center;
  background: #58D2B3;
  /* change the direction of the change here */
}

.gloabalModal img {
  width: 90px;
}

.globalLoader .msg {
  color: black;
  font-size: 22px;
  font-weight: 600;
}

.globalLoader img {
  width: 90px;
  animation: beat .4s infinite alternate;
}

/* Heart beat animation */
@keyframes beat {
  to {
    transform: scale(1.3);
  }
}



.logout {
  cursor: pointer;
}

.profileImg label .editContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center !important;
  transition: all 0.3s ease !important;
  backdrop-filter: brightness(0.65);
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
}

.profileImg label .editContainer .editIcon {
  width: 60px !important;
  height: 60px !important;
}

.profileImg:hover .editContainer {
  display: flex;
  transition: all 0.3s ease !important;
  cursor: pointer;
}

.homePage .header {
  position: relative;
  background-size: cover !important;
  /* height: 370px; */
  height: 360px;
  background-position: center !important;
  padding: 50px 0;
  position: relative;
  border-radius: 16px;
  margin-top: 12px;
  overflow: hidden;
}

.header:hover .editLabel {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  transition: all 0.3s;
}

.header:hover .editLabel:hover {
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  transition: all 0.3s;
}

.header:hover .editContainer {
  display: flex;
  transition: all 0.3s ease !important;
  cursor: pointer;
}

.header label .editContainer {
  position: absolute;
  bottom: 20px;
  right: 20px;
  /* height: 100%; */
  display: none;
  justify-content: center;
  align-items: center !important;
  transition: all 0.3s ease !important;
  /* backdrop-filter: brightness(0.65); */
}

.header label .editContainer .editIcon {
  width: 60px !important;
  height: 60px !important;
}

.subsBtn1 {
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  /* background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA 51%, #1FA2FF 100%); */

  background: #5CE7C4;
  border-radius: 20px;
  background-size: 200%;
  color: white;
  padding: 8px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  width: 100%;
  max-width: 250px;
  height: 50px;
  transition: all .3s;
}

.subsBtndisable {
  background: #849090;
  border-radius: 20px;
  background-size: 200%;
  color: white;
  padding: 8px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  width: 100%;
  max-width: 250px;
  height: 50px;
  transition: all .3s;
}

.subsBtn1:hover {
  background-position: right center;
  background: #58D2B3;
}

.dishea {
  font-size: 10px;
  font-size: 12px;
  margin-right: 9px;
  padding: 6px 16px;
  background: #F6F6F6;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  border-radius: 20px;
}
.font-bold {
  font-weight: bold;
}

.disgroup {
  display: flex;
}

.modal-heading-wallet {
  max-width: 500px;
  margin: auto;
}

.modal_visible .css-10bey84-MuiSvgIcon-root-MuiNativeSelect-icon {
  display: none;
}

.modal-heading-wallet  label{
  font-size: 18px;
}
.modal-heading-wallet select {
  background: #fff;
  color: rgb(27, 27, 27);
  padding: 10px 20px;
  /* border-radius: 7px; */
  margin-top: 8px;
  /* border-radius: 25px; */
  font-weight: bold;
  border: 1px solid rgba(0, 0, 0, 0.42);
}

.modal-heading-wallet .css-1a1fmpi-MuiInputBase-root-MuiInput-root:before {
  border-color: transparent;
}


.modal-heading-wallet h3 {
  color: red;
  font-weight: 700;
  color: rgb(27, 27, 27);
  font-size: 40px;
}

.modal-heading-wallet img:first-child {
  margin-right: 22px;
}

.homePage .navbarCustome .dropdown-toggle::after {
  display: none;
}

.modal-heading-wallet img:last-child {
  margin-left: 22px;
}

.modal-heading-wallet img {
  cursor: pointer;
  margin-top: 10px;
  width: 75px;
}

.modal-heading-wallet button {
  margin-top: 30px;
  width: 60%;
  border: none;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  /* background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA 51%, #1FA2FF 100%); */
  background: #5CE7C4;
  border-radius: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-size: 200%;
  color: white;
  transition: all .3s;
}



.ico-pay-pg {
  width: 25px;
  margin-right: 10px;
}

.modal-heading-wallet button:hover {
  background-position: right center;
  background: #58D2B3;
}

.payment-select {
  position: relative;
  padding: 15px;
}

.payment-select .arrowDown {
  position: absolute;
  right: 28px;
  top: 47px;
  width: 20px;
}
.payment-select.withLogo select {
  padding-left: 35px;
}
.payment-select .payLogo {
  width: 25px;
  position: absolute;
  left: 20px;
  top: 43px;
  z-index: 1;
}

.payment-select .label {
  margin-bottom: 10px;
}

.payment-radio .label {
  margin-bottom: 10px;
}

.radios-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row !important;
}

.form-check-input {
  margin-right: 10px;
}

.payment-radio {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  flex-direction: column;
}

.radios {
  margin-left: 10px
}

.button_payment {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}

.button_payment button:first-child {
  margin-right: 10px;
}

.nav-link-ico {
  width: 20px;
  margin-right: 10px;
}

.modal-btn-con {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.ico-modal-subs {
  width: 20px !important;
  margin: 0 !important;
  margin-right: 10px !important;
}

.button_payment button {
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  /* background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA 51%, #1FA2FF 100%); */
  /* background-image: linear-gradient(0deg, #12D8FA 0%, #1FA2FF 100%); */
  background: var(--colorBlue);
  border-radius: 25px;
  /* background-size: 200%; */
  font-weight: 500;
  transition: all .3s;
  border: none;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  /* width: 100%; */
  max-width: 100%;
  margin: auto;
  padding-right: 25px;
  padding-left: 25px;
  margin-top: 25px !important;
  color: white;
}

.button_payment button:hover {
  background-position: right center;
  background: #1E179F;
  /* change the direction of the change here */
}

.dash_cont {
  padding: 10px 0;
}

.dash_card {

  margin-top: 20px;
  min-height: 130px;
}



.dash_card div {
  padding: 10px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 20px, rgba(0, 0, 0, 0.13) 0px 6px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 130px;
  flex-direction: column;
  position: relative;
}

.dash_card_heading {
  margin-bottom: 10px;
  color: #17447E;
  font-weight: bold;
  font-size: 18px;
}

.dash_card_text {
  color: #161616;
  font-size: 16px;
  font-weight: 600;
}

.admin-pay-model {
  padding-top: 20px;
  width: 100%;
}

.admin-edit-model {
  padding-top: 20px;
  width: 100%;
}

.addModal {
  padding: 20px;
  width: 100%;
}

.addModal div button {
  width: 100%;
  border-radius: 15px;
  padding: 8px;
  color: white;
  border: none;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  /* background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA 51%, #1FA2FF 100%); */
  background: #5CE7C4;
  background-size: 200%;
  transition: all .3s;
}

.addModal div {
  width: 100%;
}

.addModal div button:first-child {
  margin-right: 5px;
  margin-left: 10px;
}

.addModal div button:last-child {
  margin-left: 5px;
  margin-right: 10px;
}

.addModal div button:hover {
  background-position: right center;
  background: #58D2B3;
  /* change the direction of the change here */
}


.admin-edit-model div button {
  width: 100%;
  border-radius: 15px;
  padding: 8px;
  color: white;
  border: none;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  /* background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA 51%, #1FA2FF 100%); */
  background: #5CE7C4;
  background-size: 200%;
  transition: all .3s;
}

.admin-edit-model div {
  width: 100%;
}

.admin-edit-model div button:first-child {
  margin-right: 5px;
  margin-left: 10px;
}

.admin-edit-model div button:last-child {
  margin-left: 5px;
  margin-right: 10px;
}

.admin-edit-model div button:hover {
  background-position: right center;
  background: #58D2B3;
  /* change the direction of the change here */
}

.admin-pay-model div {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.admin-pay-model div button:first-child {
  margin-right: 5px;
  margin-left: 10px;
}

.admin-pay-model div button:last-child {
  margin-left: 5px;
  margin-right: 10px;
}

.admin-pay-model div button {
  width: 100%;
  border-radius: 15px;
  padding: 8px;
  color: white;
  border: none;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  /* background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA 51%, #1FA2FF 100%); */
  background: #5CE7C4;
  background-size: 200%;
  transition: all .3s;
}

.admin-pay-model div button:hover {
  background-position: right center;
  background: #58D2B3;
  /* change the direction of the change here */
}

.payment-select-admin {
  display: flex;
  width: 100%;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
  padding: 15px;
  font-weight: bold;
}

.payment-select-admin .label {
  width: 100%;
  display: flex;
  text-align: center !important;
  margin-bottom: 10px;
  justify-content: center;
}

.cls-btn-pay-admin {
  margin-left: 10px;
}

.cntr {
  margin: auto;
}

.btn-radio {
  cursor: pointer;
  display: inline-block;
  float: left;
  -webkit-user-select: none;
  user-select: none;
}

.btn-radio:not(:first-child) {
  margin-left: 20px;
}

@media screen and (max-width: 480px) {
  .btn-radio {
    display: block;
    float: none;
  }

  .btn-radio:not(:first-child) {
    margin-left: 0;
    margin-top: 15px;
  }
}

.btn-radio svg {
  fill: none;
  vertical-align: middle;
}

.btn-radio svg circle {
  stroke-width: 2;
  stroke: #C8CCD4;
}

.btn-radio svg path {
  stroke: #008FFF;
}

.btn-radio svg path.inner {
  stroke-width: 6;
  stroke-dasharray: 19;
  stroke-dashoffset: 19;
}

.btn-radio svg path.outer {
  stroke-width: 2;
  stroke-dasharray: 57;
  stroke-dashoffset: 57;
}

.btn-radio input {
  display: none;
}

.btn-radio input:checked+svg path {
  transition: all 0.4s ease;
}

.btn-radio input:checked+svg path.inner {
  stroke-dashoffset: 38;
  transition-delay: 0.3s;
}

.btn-radio input:checked+svg path.outer {
  stroke-dashoffset: 0;
}

.btn-radio span {
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
}

.address-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.address-header button {
  border: none !important;
  border-radius: 5px !important;
  background-color: #e2e2e2 !important;
  width: 150px;
  padding: 8px;
}

.corssBlackIco-modal {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  width: 25px !important;
}

.homePage .userNavigationMain .closeLeftNav {
  display: none;
}

.homePage .leftSideBarBtn {
  width: 50px;
  padding: 5px 10px;
  background: #3a96e5;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  position: absolute;
  left: 0px;
  top: 490px;
  display: none;
  color: white;
}

@media screen and (min-width:200px) and (max-width:992px) {

  .disgroup {
    display: block;
  }


}

@media screen and (min-width:1200px) {
  .homePage .sidebarMain {
    position: sticky;
    top: 10px;
    margin-top: -100px;
  }

  .homePage .signalCardMain {
    position: sticky;
    top: 10px;
    margin-top: -100px;
  }
}

@media screen and (max-width:1200px) {
  .homePage .leftSideBarBtn {
    display: block;
  }

  .homePage .userNavigationMain {
    position: fixed;
    left: -230px;
    top: 0;
    bottom: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 230px;
    z-index: 2;
    min-height: 560px;
    overflow-y: auto;
    transition: all 0.3s;
  }

  .homePage .userNavigationMain .closeLeftNav {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    border-radius: 50%;
    background-image: linear-gradient(0deg, #12D8FA 0%, #1FA2FF 100%);
    color: white;
    transition: all 0.3s;
  }
}

@media screen and (min-width:1200px) and (max-width:1400px) {
  .homePage .projectsRow .projectCard {
    padding: 25px 12px;
  }
  .homePage .projectsRow .projectCard .title {
    font-size: 14px;
  }
}
@media screen and (min-width:992px) and (max-width:1200px) {
  .signupPage h2 {
    font-size: 41px;
  }
}

@media screen and (max-width:992px) {
  .homePage .profileMain form {
    width: 100%;
  }

  .signupPage .signupCover {
    display: none;
  }

  .signupPage .element {
    max-width: 75%;
  }
}

@media screen and (min-width:576px) and (max-width:768px) {
  .homePage .leftSideBarBtn {
    top: 415px;
  }
  
}

@media screen and (max-width:768px) {
  .homePage .header {
    height: 250px;
  }

  .homePage .profileSec .profileImg {
    width: 150px;
    height: 150px;
  }

  .homePage .profileSec {
    margin-top: -106px;
  }
}

button:hover {
  cursor: pointer !important;
}

.mwalletadd {
  display: flex;
  justify-content: center;
  align-items: center;
}

.walletAdd {
  border: none !important;
  border-radius: 5px !important;
  background-color: #e2e2e2 !important;
  width: 150px;
  padding: 8px;
}

.TWOFAQR {
  width: 50% !important;
  margin-bottom: 30px;
}

.TwoFaText {
  padding: 15px;
}

.input-admin-edit {
  padding: 15px;
}

.homePage .signalsMain {
  /* background: white; */
  padding: 20px 10px;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;
  flex-direction: column;
}

.signalsHeading {
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 20px;

}

.signals {
  width: 100%;
}

.signalsCont {
  margin-bottom: 20px;
  background-color: #fff;
  padding: 20px;
  font-size: 16px;
  border-radius: 10px;
  font-weight: 500;
  color: rgb(66, 66, 66);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}

.postedBy {
  font-size: 16px;
  font-weight: 800;
  color: rgb(65, 65, 65);
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.post {
  font-size: 16px;
  font-weight: 500;
  color: rgb(114, 114, 114);
  margin-bottom: 10px;
  white-space: pre-line;
  /* max-height: 72px;
  overflow: hidden; */
}

.postMa .showMoreB {
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
  margin: 20px 0;
}


.time {
  font-size: 14px;
  font-weight: 400;
  color: rgb(110, 110, 110);
  margin-top: 10px;
  margin-left: auto;
}

.icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.addButton {
  position: absolute;
  right: 30px;
  top: 30px;
}

.addButton img {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.bottom-cont-posts {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  width: 100%;
}

.top-0 {
  top: 0 !important;
}

.bottom-cont-posts .icons img {
  width: 25px;
  margin-left: auto;
  margin-right: 10px;
  cursor: pointer;
}

.fileUpload-modal {
  margin-top: 20px;
  height: 150px;
}

.fileUpload-modal label {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  border: 2px dashed;
  border-color: #adadad;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
}

.fileUpload-modal label img {
  width: 30px;
}

.fileUpload-modal label .img_sel {
  width: 100%;
}

.fileUpload-modal label input {
  display: none;
}



.postImage {
  min-width: 100%;
  /* min-height: 400px; */
  max-height: 500px;
  border-radius: 7px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

}

.postImagesrc {
  max-width: 100%;
  width: auto;
}

@media screen and (min-width:1000px) {
  .label-rdbtn {
    width: 200px;
  }

}

.GuidesImgCont {
  height: 180px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e4e4e4;
  position: relative;
}
.card-width-guides {
  position: relative;
  border-radius: 13px;
  border: none;
  box-shadow: 0px 7px 7px 0px rgb(0 0 0 / 18%);
  overflow: hidden;
  top: 0;
  transition: all 0.3s;
}
.card-width-guides:hover {
  box-shadow: 0px 12px 7px 0px rgb(0 0 0 / 18%);
  top: -5px;
  transition: all 0.3s;
}
.card-guides-para {
  font-weight: 600;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.playIcon {
  /* position: absolute; */
  /* right: 10px;
  bottom: 10px; */
  width: 24px;
  cursor: pointer;
}

.row-guides {
  width: 100%;
}

.deleteIcoGuides {
  position: absolute;
  left: 10px;
  bottom: 10px;
  cursor: pointer;
  width: 25px !important;
}

.editIcoGuides {
  position: absolute;
  left: 40px;
  bottom: 10px;
  cursor: pointer;
  width: 25px !important;
}

.categoryButton {
  background-color: #C8F2E7;
  border: none;
  padding: 6px;
  font-size: 12px;
  border-radius: 25px;
  min-width: 100px;
  margin-bottom: 30px;
  cursor: pointer;
  transition: all 0.3s;
}
.categoryButton:hover {
  box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 19%);
  transition: all 0.3s;
}

.ai-fs {
  align-items: flex-start;
}

.as-fs {
  align-self: flex-start;
}


.guideModalVideo iframe {
  width: 100%;
  height: 400px;
  border-radius: 5px;
}

.issueButtons {
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  /* background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA 51%, #1FA2FF 100%); */
  background: #5CE7C4;
  border-radius: 10px;
  background-size: 200%;
  color: white;
  padding: 8px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  width: 100%;
  transition: all .3s;
}

.issueButtons:hover {
  background-position: right center;
  background: #58D2B3;
  /* change the direction of the change here */
  transition: all 0.4s;
}

.uparrow-signal {
  transform: rotate(180deg);
}

.avatar-cont-gui-sig {
  border-radius: 100px;
  width: 40px;
  overflow: hidden;
  margin-right: 10px;
  border: rgba(184, 184, 184, 0.384) 4px solid;
}


.avatar-cont-gui-sig img {
  width: 100%;
}

.card-img-top {
  cursor: pointer;
}

.reactions-icon-label-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.reactions-icon-label-cont button {
  border-radius: 9px;
  border: 1px solid #969696;
  color: #969696;
}

.reactions-icon-label-cont button img {
  width: 18px !important;
}

.reactions-icon-label-cont .bgSuccess:hover,
.reactions-icon-label-cont .bgSuccess.active {
  background: #00D131;
  color: #fff;
}
.reactions-icon-label-cont button:focus{
  color: #969696;
  box-shadow: none;
}


.reactions-icon-label-cont .bgLoss:hover,
.reactions-icon-label-cont .bgLoss.active {
  background: #FF2929;
  color: #fff;
}

/* .reactions-icon-label-cont span {
  margin-right: 5px;
} */

.submit-btn-modal-c {
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  /* background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA 51%, #1FA2FF 100%); */

  background: #5CE7C4;
  border-radius: 22px;
  background-size: 200%;

  color: white;
  padding: 12px;
  font-size: 18px;
  font-weight: 500;
  border: none;
  width: 100%;
  transition: all 0.3s;
}

.submit-btn-modal-c:hover {
  background-position: right center;
  background: #58D2B3;
  /* change the direction of the change here */
  transition: all 0.3s;

}

.button-pr-los {
  margin-right: 10px;
  color: white;
  font-size: 15px;
}

.button-pr-los:hover {
  color: white;
}

.button-pr-los:focus {
  color: white;
}

.tableModel {
  max-height: 350px;
  overflow: auto;
}

.export-user-cont {
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
}

.exportcsv-user {
  margin-bottom: 20px;
  font-weight: 600;
  cursor: pointer;
}

.chat-issue-md {
  width: 100%;
  height: 250px;
  overflow: auto;
}

.reply-admin-md {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: left;
  background-color: #dadada3b;
  border-radius: 10px;
  padding: 10px;
  border-top-left-radius: 0px;
}

.reply-user-md {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: right;
  background-color: #a1a1a17e;
  border-radius: 10px;
  padding: 10px;
  border-bottom-right-radius: 0px;
}

.dateTool {
  position: relative;
  cursor: alias;
}

.dateTool:hover .fullDate {
  display: block;

}

.dateTool .fullDate {
  position: absolute;
  padding: 7px;
  border-radius: 6px;
  background-color: black;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
  bottom: -45px;
  color: white;
  font-size: 12px;
  display: none;
}

@media screen and (max-width:576px) {
  .homePage .navbarCustome {
    padding: 1px 8px;
  }
  .homePage .navbarCustome .linkDiv button {
    padding: 4px;
  }
  .homePage .signalsMain {
    padding: 20px 5px;
  }

  .homePage .leftSideBarBtn {
    top: 415px;
  }

  .gloabalModal .modal_visible {
    width: 95%;
  }

  .signalsCont {
    padding: 12px;
  }
  .homePage .notifyDropD {
    width: 300px; 
  }

}

.btnShowMoreCont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.showMoreButton {
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  /* background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA 51%, #1FA2FF 100%); */
  background: #5CE7C4;
  border-radius: 10px;
  background-size: 200%;
  color: white;
  padding: 8px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  width: 200px;
  transition: all .3s;
  margin-top: 10px;
}
.showMoreButton:hover {
  background: #58D2B3;
}
.w-mc {
  width: max-content;
}
 tbody tr.spacer td {
  padding: 0 !important;
  height: 10px;
  border-radius: 0 !important;
  background: transparent !important;
 }

 table tbody tr th:first-child, table tbody tr td:first-child {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
 }
 table tbody tr th:last-child, table tbody tr td:last-child {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
 }
